// Website URL //
let Test_API_URL = "https://api.mesotravel.com/api/";
let Main_API_URL = "https://api.mesotravel.com/api/";
// let Test_Image_URL = "http://localhost:5000/";
// let Main_Image_URL = "http://localhost:5000/";
// if (window.location.hostname == "localhost") {
//   Test_API_URL = "http://localhost:5000/api/";
//   Main_API_URL = "http://localhost:5000/api/";
//   Test_Image_URL = "http://localhost:5000/";
//   Main_Image_URL = "http://localhost:5000/";
// }
const testnetConfig = {
  Base_URL: Test_API_URL,
};

const mainnetConfig = {
  Base_URL: Main_API_URL,
};

const defaultConfig = mainnetConfig;


// Export URLs //

export { defaultConfig };
